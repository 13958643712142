// prettier-ignore
import Details from "./details";

export class SendMoney {
  constructor (view) {
    this.view = view;
  }

  // Step movement helpers
  openFirstIncompleteStep () {
    const currentStep = this.view.detailsTargets.find((detailsTarget) => {
      const targetOrder = detailsTarget.dataset.order * 1 - 1;
      const detail = new Details(detailsTarget);
      const hasErrors = detailsTarget.querySelector(".error");

      // If recipient is not set or if there are nested elements with .errors class, then return this step
      if (this.validateStep(targetOrder) && !hasErrors) {
        return detail.setCompleted(true);
      }
      return true;
    });

    this.select(currentStep);
  }

  validateStep (step) {
    // Start [recurring-transfers-milestone-1] Infer feature flag from the presence of the date type target
    // Hide the date review items
    if (!this.view.hasDateTypeTarget) {
      this.view.reviewDateFrequencyCustomTargets.forEach((target) => {
        target.closest(".review_item")?.classList?.add("hidden");
      });
      this.view.reviewDateTermTargets.forEach((target) => {
        target.closest(".review_item")?.classList?.add("hidden");
      });
    }
    // End [recurring-transfers-milestone-1]

    if (step === 0) {
      return this.validateRecipient();
    } else if (step === 1) {
      return this.validatePaymentMethod();
    } else if (step === 2) {
      return this.validateAmount();
    } else if (step === 3) {
      return this.validateDate();
    }
    return false;
  }

  validateAndToggleNextButton () {
    let disableFollowingSteps = false;
    this.view.detailsTargets.forEach((detailsTarget) => {
      const targetOrder = detailsTarget.dataset.order * 1 - 1;
      const nextButton = detailsTarget.querySelector(
        "[data-action='click->send-money#onNext']",
      );
      const isValid = this.validateStep(targetOrder);

      // Toggle the disabled state of the next button
      if (nextButton) {
        nextButton.disabled = !isValid;
      }

      // Toggle the disabled state of the following steps
      detailsTarget.disabled = disableFollowingSteps;
      if (!isValid) {
        disableFollowingSteps = true;
      }
    });
  }

  configureCancellationNotice () {
    if (this.view.hasCancellationNoticeTarget && this.view.hasDateTypeTarget && this.view.hasDateOneTimeOptionsTarget) {
      this.view.cancellationNoticeTarget.classList.add("hidden");

      if (this.view.dateTypeTarget.value === "one_time" && this.view.dateOneTimeOptionsTarget.value === "on_date") {
        this.view.cancellationNoticeTarget.classList.remove("hidden");
      } else if (this.view.dateTypeTarget.value === "recurring") {
        this.view.cancellationNoticeTarget.classList.remove("hidden");
      }
    }
  }

  select (detailsEventTarget) {
    const currentOrder = detailsEventTarget?.dataset?.order;
    this.view.detailsTargets.forEach((detailsTarget) => {
      const targetOrder = detailsTarget.dataset.order;
      const details = new Details(detailsTarget);
      if (targetOrder < currentOrder && this.validateStep(targetOrder - 1)) {
        details.setCompleted(true);
      } else {
        details.setSelected(detailsEventTarget === detailsTarget);
      }
    });
    this.updateReviewValues();
  }

  next (target) {
    const selectedDetails = this.detailsFromButtonTarget(target);
    selectedDetails.setCompleted(true);
    this.select(this.nextDetails(selectedDetails));
  }

  back (target) {
    const selectedDetails = this.detailsFromButtonTarget(target);
    selectedDetails.setCompleted(false);
    this.select(this.previousDetails(selectedDetails));
  }

  nextDetails (currentDetails) {
    return this.view.detailsTargets[this.indexFromDetails(currentDetails) + 1];
  }

  previousDetails (currentDetails) {
    return this.view.detailsTargets[this.indexFromDetails(currentDetails) - 1];
  }

  detailsFromButtonTarget (currentTarget) {
    while (currentTarget && currentTarget.tagName !== "SL-DETAILS") {
      if (currentTarget.dataset.order) {
        break;
      }
      currentTarget = currentTarget.parentElement;
    }
    return new Details(currentTarget);
  }

  indexFromDetails (currentDetails) {
    return this.view.detailsTargets.indexOf(currentDetails.element);
  }

  // Review Step helpers
  updateReviewValues () {
    this.updateReviewRecipient();
    this.updateReviewPaymentMethod();
    this.updateReviewAmount();
    this.updateReviewMemo();
    this.updateReviewNote();
    this.validateAndToggleNextButton();
    this.configureCancellationNotice();
  }

  sanitizeText (text) {
    if (!text) {
      return "";
    }

    const map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      "\"": "&quot;",
      "'": "&#x27;",
      "/": "&#x2F;",
    };
    const reg = /[&<>"'/]/gi;
    return text.replace(reg, (match) => map[match]);
  }

  // Form Step detail helpers
  // Step: Recipient
  selectRecipient (eventTarget) {
    if (this.updateReviewRecipient()) {
      // Reset blows away selected value, so save it
      const selectedPaymentMethodId = this.view.paymentMethodTarget.value;

      // Reset if the recipient has changed
      if (
        this.view.paymentMethodCustomerIdTarget.value !==
        this.view.recipientTarget.value
      ) {
        this.resetPaymentMethodOptions();
        this.view.paymentMethodOptionsTarget.src = `/stripe_customers/${this.view.recipientTarget.value}/stripe_payment_methods`;
      }

      if (!selectedPaymentMethodId) {
        this.next(eventTarget);
      }
      this.updateReviewValues();
    }
  }

  resetPaymentMethodOptions () {
    this.view.paymentMethodTarget.value = null;
    this.view.reviewPaymentMethodTargets.forEach((target) => {
      target.innerHTML = "";
    });
    this.view.reviewPaymentDeliveryTargets.forEach((target) => {
      target.innerHTML = "";
    });
  }

  validateRecipient () {
    return this.view.recipientTarget.value.length > 0;
  }

  updateReviewRecipient () {
    if (!this.validateRecipient()) {
      return;
    }

    // Get the selected name from the sl-select option
    const selectedValue = this.view.recipientTarget.value;
    const selectedOption = this.view.recipientTarget.querySelector(
      "sl-option[value='" + selectedValue + "']",
    );

    // BEGIN workaround to support rich HTML (sl-tag) in sl-select label
    const replacementInput = this.view.recipientTarget.shadowRoot.querySelector(
      "[slot=\"anchor\"] .select__display-input",
    );
    const replacementDiv = document.createElement("div");
    Array.from(replacementInput.attributes).forEach((attr) => {
      replacementDiv.setAttribute(attr.name, attr.value);
    });
    replacementDiv.innerHTML = selectedOption.innerHTML;
    replacementInput.parentNode.replaceChild(replacementDiv, replacementInput);
    // END workaround

    // dispatch the update for any listening controllers
    const recipientDetails = {
      name: selectedOption.innerHTML,
      id: this.view.recipientTarget.value,
    };
    this.view.dispatch("recipientSet", { detail: recipientDetails });

    // Update recipient step details
    this.view.reviewRecipientTargets.forEach((target) => {
      target.innerHTML = selectedOption.innerHTML;
    });

    return true;
  }

  // Step: Payment Method
  selectPaymentMethod (eventTarget) {
    if (this.updateReviewPaymentMethod()) {
      this.updateReviewValues();
      this.next(eventTarget);
    }
  }

  validatePaymentMethod () {
    const paymentMethodId = this.view.paymentMethodTarget.value * 1;
    return paymentMethodId > 0;
  }

  updateReviewPaymentMethod () {
    if (!this.view.hasPaymentMethodTarget || !this.validatePaymentMethod()) {
      return;
    }

    const sourceTarget = this.view.paymentMethodTarget;
    const selectedButton = sourceTarget.querySelector(
      `sl-radio[value='${sourceTarget.value}']`,
    );

    if (!selectedButton) {
      console.warn(
        "[updateReviewPaymentMethod] no selected button found",
        sourceTarget.value,
        sourceTarget,
      );
      return;
    }

    // Update amount step details
    const selectedPaymentMethodType = selectedButton.dataset.name.toUpperCase();

    if (selectedPaymentMethodType === "CHECK") {
      this.view.externalMemoAreaTarget.classList.remove("hidden");
      this.view.reviewExternalMemoAreaTarget.classList.remove("hidden");
    } else {
      this.view.externalMemoAreaTarget.classList.add("hidden");
      this.view.reviewExternalMemoAreaTarget.classList.add("hidden");
    }

    // Update form values
    this.view.paymentMethodIdTarget.value = selectedButton.value;
    this.view.paymentMethodTypeTarget.value = selectedButton.dataset.objectType;

    this.view.reviewPaymentMethodTargets.forEach((target) => {
      target.innerHTML = this.sanitizeText(
        selectedButton.dataset.name +
          " (" +
          selectedButton.dataset.description +
          ")",
      );
    });

    this.view.reviewPaymentDeliveryTargets.forEach((target) => {
      target.innerHTML = this.sanitizeText(selectedButton.dataset.eta);
    });

    return true;
  }

  // Step: Amount
  setAmount () {
    this.updateReviewValues();
  }

  validateAmount () {
    return (
      this.amountIsNumeric() &&
      this.amountGreaterThanZero() &&
      this.amountNotGreaterThanBalance()
    );
  }

  amountIsNumeric () {
    const inputPattern = new RegExp(this.view.amountTarget.pattern);
    const amount = this.view.amountTarget.value * 1;
    return inputPattern.test(amount);
  }

  amountGreaterThanZero () {
    const amount = this.view.amountTarget.value * 1;
    return amount > 0;
  }

  amountNotGreaterThanBalance () {
    if (!this.view.hasAvailableBalanceTarget || !this.view.hasAmountTarget) {
      console.warn(
        "[amountNotGreaterThanBalance] missing target",
        this.view.availableBalanceTarget,
      );
      return false;
    }
    const amount = Math.round(this.view.amountTarget.value * 100);
    const availableBalance = this.view.availableBalanceTarget.value * 1;
    return amount <= availableBalance;
  }

  updateReviewAmount (amountType) {
    if (!this.validateAmount()) {
      return;
    }

    this.view.reviewAmountTargets.forEach((target) => {
      target.innerHTML = this.sanitizeText(this.getAmount(amountType));
    });

    return true;
  }

  getAmount () {
    return this.formatToMoney(this.view.amountTarget.value);
  }

  formatToMoney (amount) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return formatter.format(amount);
  }

  // Step: Memo
  setMemo () {
    this.updateReviewValues();
  }

  updateReviewMemo () {
    const sourceTarget = this.view.memoTarget;

    this.view.reviewExternalMemoTargets?.forEach((target) => {
      target.innerHTML =
        this.sanitizeText(sourceTarget.value) ||
        target.getAttribute("placeholder");
    });

    // Update subtext step details
    this.view.reviewAmountSubtextTargets?.forEach((target) => {
      target.innerHTML = this.sanitizeText(sourceTarget.value);
    });

    return true;
  }

  // Step: Note
  setNote () {
    this.updateReviewValues();
  }

  updateReviewNote () {
    const sourceTarget = this.view.noteTarget;

    this.view.reviewNoteTargets?.forEach((target) => {
      target.innerHTML =
        this.sanitizeText(sourceTarget.value) ||
        target.getAttribute("placeholder");
    });

    return true;
  }

  // Step: Date
  selectDate (eventTarget) {
    if (this.updateReviewDate()) {
      this.updateReviewValues();
      this.next(eventTarget);
    }
  }

  setDateType () {
    this.view.dateTypeTarget.value =
      this.view.dateTypeTarget.value || "one_time";

    this.view.dateOneTimeContainerTarget.classList.add("hidden");
    this.view.dateRecurringContainerTarget.classList.add("hidden");

    if (this.view.dateTypeTarget.value === "one_time") {
      this.view.dateOneTimeContainerTarget.classList.remove("hidden");
    } else if (this.view.dateTypeTarget.value === "recurring") {
      this.view.dateRecurringContainerTarget.classList.remove("hidden");
    }

    this.updateReviewDate();
  }

  validateDate () {
    // Ignore if date isn't rendered
    if (!this.view.hasDateTypeTarget) {
      return true;
    }

    // Remove error classes to reevaluate
    this.view.dateContainerTarget
      .querySelectorAll(".error")
      .forEach((error) => error.classList.remove("error"));

    // Is a date type selected?
    if (!this.view.dateTypeTarget.value) {
      return false;
    }

    // Is a one-time date selected?
    if (this.is_date_one_time()) {
      return this.validateOneTimeDate();
    }

    // Is a recurring date selected?
    if (this.is_date_recurring()) {
      return this.validateRecurringDate();
    }

    return true;
  }

  validateOneTimeDate () {
    if (this.view.dateOneTimeOptionsTarget.value === "on_date") {
      const isBlank = this.view.dateOneTimePickerTarget.value === "";

      if (isBlank) {
        this.view.dateOneTimePickerTarget.classList.add("error");
        return false;
      }
    }

    if (
      this.view.dateOneTimeContainerTarget.querySelector("[data-user-invalid]")
    ) {
      return false;
    }

    return true;
  }

  validateRecurringDate () {
    if (this.view.dateRecurringEndOptionsTarget.value === "on_date") {
      let validDateRange = true;

      if (this.view.dateRecurringFrequencyTarget.value === "1_15") {
        const dateStartDate = new Date(
          this.view.dateRecurringFirstAndFifteenthStartDayTarget.value,
        );
        const dateEndDate = new Date(
          this.view.dateRecurringFirstAndFifteenthEndDayTarget.value,
        );
        validDateRange = !isNaN(dateStartDate.getTime()) && !isNaN(dateEndDate.getTime()) && dateStartDate <= dateEndDate;
      } else {
        const dateStartDate = new Date(
          this.view.dateRecurringStartPickerTarget.value,
        );
        const dateEndDate = new Date(
          this.view.dateRecurringEndPickerTarget.value,
        );
        validDateRange = !isNaN(dateStartDate.getTime()) && !isNaN(dateEndDate.getTime()) && dateStartDate <= dateEndDate;
        if (this.view.dateRecurringEndPickerTarget.value === "") { this.view.dateRecurringEndPickerTarget.classList.add("error"); }
      }

      return validDateRange;
    } else {
      this.view.dateRecurringEndPickerTarget.value = "";
      this.view.dateRecurringFirstAndFifteenthEndDayTarget.value = "";
    }

    return true;
  }

  is_date_one_time () {
    return this.view.dateTypeTarget.value === "one_time";
  }

  is_date_recurring () {
    return this.view.dateTypeTarget.value === "recurring";
  }

  // Reformat YYYY-MM-DD to MM/DD/YYYY for display purposes
  convert_iso_date_to_friendly (date) {
    if (!date) {
      return "";
    }

    const dateParts = date.split("-");
    return dateParts[1] + "/" + dateParts[2] + "/" + dateParts[0];
  }

  is_recurring_end_more_than_6_months_after_start () {
    if (!this.is_date_recurring()) {
      return false;
    }

    const dateFrequencyNever =
      this.view.dateRecurringEndOptionsTarget.value === "never";

    let dateStartField;
    let dateEndField;

    if (this.view.dateRecurringFrequencyTarget.value === "1_15") {
      dateStartField = this.view.dateRecurringFirstAndFifteenthStartDayTarget;
      dateEndField = this.view.dateRecurringFirstAndFifteenthEndDayTarget;
    } else {
      dateStartField = this.view.dateRecurringStartPickerTarget;
      dateEndField = this.view.dateRecurringEndPickerTarget;
    }

    if (!dateStartField.value || dateStartField.value === "") {
      return false;
    }

    // Never ends
    if (dateFrequencyNever) {
      return true;

      // If the end date is not set yet, let's not assume it's more than 6 months away
    } else if (!dateEndField.value || dateEndField.value === "") {
      return false;
    }

    // Convert Date start string (YYYY-MM-DD) to Date object
    let dateParts = dateStartField.value.split("-");
    const dateStart = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

    // Convert Date end string (YYYY-MM-DD) to Date object
    dateParts = dateEndField.value.split("-");
    const dateEnd = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

    // Get start date plus 6 months
    const dateFuture = dateStart.setMonth(dateStart.getMonth() + 6);

    return dateFuture < dateEnd;
  }

  configureDateOptions () {
    this.resetDateNotices();
    this.configureOneTimeDateOptions();
    this.configureDateRecurringOptions();

    this.updateReviewDate();
    this.updateReviewValues();
  }

  // Hide notices by default and let the configuration functions show them
  resetDateNotices () {
    this.view.dateNonBankingDayNoticeTargets.forEach((target) =>
      target.classList.add("hidden"),
    );
    this.view.dateRecurringReapprovalNoticeTargets.forEach((target) =>
      target.classList.add("hidden"),
    );
    this.view.weeklyTransfersNoticeTarget.classList.add("hidden");
    this.view.oneTimeExpirationNoticeTargets.forEach(
      target => target.classList.add("hidden"),
    );
  }

  configureOneTimeDateOptions () {
    // Disable date picker if the date is set to on_approval
    this.view.dateOneTimePickerTarget.disabled =
      this.view.dateOneTimeOptionsTarget.value === "on_approval";

    // Show non-banking day notice if the date is set to a weekend or holiday
    if (this.view.dateOneTimeOptionsTarget.value === "on_date") {
      const selectedDate = new Date(this.view.dateOneTimePickerTarget.value);
      const day = selectedDate.getDay(); // Day of the week
      const isWeekend = day >= 5; // The Weeknd

      // Show notice if date falls on a weekend
      if (isWeekend || this.isHoliday(selectedDate)) {
        this.view.dateNonBankingDayNoticeTargets.forEach((target) =>
          target.classList.remove("hidden"),
        );
      }

      this.view.oneTimeExpirationNoticeTargets.forEach((target) => target.classList.remove("hidden"));
    }
  }

  isHoliday (date) {
    const holidays = JSON.parse(this.view.dateContainerTarget.dataset.holidays);
    return holidays.includes(date.toISOString().split("T")[0]);
  }

  configureDateRecurringOptions () {
    // Disable the end date options if the recurring end date is set to never

    if (this.view.dateRecurringEndOptionsTarget.value === "never") {
      this.view.dateRecurringEndPickerTarget.disabled = true;
      this.view.dateRecurringFirstAndFifteenthEndDayTarget.disabled = true;
      this.view.dateRecurringFirstAndFifteenthEndDayTarget.value = "";
    } else {
      this.view.dateRecurringEndPickerTarget.disabled = false;
      this.view.dateRecurringFirstAndFifteenthEndDayTarget.disabled = false;

      if (this.view.dateRecurringFirstAndFifteenthStartDayTarget.value !== "") {
        // Generate the end date options for the 1st and 15th of the month
        this.generateFirstAndFifteenthEndDates();

        if (this.view.dateRecurringFirstAndFifteenthEndDayTarget.value < this.view.dateRecurringFirstAndFifteenthStartDayTarget.value) {
          this.generateFirstAndFifteenthEndDates();
          this.view.dateRecurringFirstAndFifteenthEndDayTarget.value = "";
        }
      }
    }

    // Show reapproval notice if more than 6 months away from start date and end date is set to never or after end date
    if (this.is_recurring_end_more_than_6_months_after_start()) {
      this.view.dateRecurringReapprovalNoticeTargets.forEach((target) => target.classList.remove("hidden"));
    }

    if (this.view.dateRecurringFrequencyTarget.value === "1_15") {
      // Hide the monthly and weekely date pickers
      this.view.weeklyTransfersNoticeTarget.classList.add("hidden");
      this.view.dateRecurringStartPickerTarget.classList.add("hidden");
      this.view.dateRecurringEndPickerTarget.classList.add("hidden");
      this.view.dateRecurringFirstAndFifteenthStartDayTarget.classList.remove("hidden");
      this.view.dateRecurringFirstAndFifteenthEndDayTarget.classList.remove("hidden");
    } else {
      // Show the monthly and weekely date pickers
      if (this.view.dateRecurringFrequencyTarget.value === "weekly") { this.view.weeklyTransfersNoticeTarget.classList.remove("hidden"); }
      this.view.dateRecurringStartPickerTarget.classList.remove("hidden");
      this.view.dateRecurringEndPickerTarget.classList.remove("hidden");
      this.view.dateRecurringFirstAndFifteenthStartDayTarget.classList.add("hidden");
      this.view.dateRecurringFirstAndFifteenthEndDayTarget.classList.add("hidden");
    }

    // Set the minimum end date to the start date plus one day
    if (this.view.dateRecurringStartPickerTarget.value) {
      const startDate = new Date(this.view.dateRecurringStartPickerTarget.value);
      startDate.setDate(startDate.getDate() + 1);
      this.view.dateRecurringEndPickerTarget.setAttribute("min", startDate.toISOString().split("T")[0]);
    }
  }

  updateReviewDate () {
    if (!this.validateDate()) {
      return;
    }

    const frequencyNode = this.view.dateTypeTarget;
    let selectedFrequency = frequencyNode.querySelector(`sl-radio[value='${frequencyNode.value}']`).innerHTML; // eg. One-time or Recurring
    let reviewFrequencyDescription = selectedFrequency;
    let reviewDateDescription = ""; // eg. 01/01/2021 or 01/01/2021 - 01/01/2022
    let reviewDateLabel; // eg. Date, Start date, Start/End date

    // Format the date for display
    // One-time date
    if (this.is_date_one_time()) {
      // Set the review date label
      const selectedEndOption = this.view.dateOneTimeOptionsTarget.querySelector("sl-radio[value='" + this.view.dateOneTimeOptionsTarget.value + "']");
      reviewDateLabel = selectedEndOption.getAttribute("label");

      // Set the review date term
      const selectedDate = this.view.dateOneTimePickerTarget.value;
      if (this.view.dateOneTimeOptionsTarget.value === "on_date") {
        selectedFrequency += " (" + this.convert_iso_date_to_friendly(selectedDate) + ")";
        reviewDateDescription = this.convert_iso_date_to_friendly(selectedDate);
      } else {
        reviewDateDescription = selectedEndOption.innerHTML;
      }
    } else if (this.is_date_recurring()) {
      // Get the selected frequency (eg. Monthly, weekly, 1st and 15th of the month)
      const selectedDateFrequency = this.view.dateRecurringFrequencyTarget.querySelector("sl-option[value='" + this.view.dateRecurringFrequencyTarget.value + "']");

      selectedFrequency += " (" + selectedDateFrequency.innerHTML + ")";
      reviewFrequencyDescription = selectedFrequency;

      // If the end date is set to never, only show the start date (eg. 01/01/2021)
      if (this.view.dateRecurringEndOptionsTarget.value === "never") {
        if (this.view.dateRecurringFrequencyTarget.value === "1_15") {
          reviewDateDescription = this.convert_iso_date_to_friendly(this.view.dateRecurringFirstAndFifteenthStartDayTarget.value);
        } else {
          reviewDateDescription = this.convert_iso_date_to_friendly(this.view.dateRecurringStartPickerTarget.value);
        }

      // If the end date is set to after end date, show the start and end date (eg. 01/01/2021 - 01/01/2022)
      } else {
        if (this.view.dateRecurringFrequencyTarget.value === "1_15") {
          reviewDateDescription = this.convert_iso_date_to_friendly(this.view.dateRecurringFirstAndFifteenthStartDayTarget.value) + " - " + this.convert_iso_date_to_friendly(this.view.dateRecurringFirstAndFifteenthEndDayTarget.value);
        } else {
          reviewDateDescription = this.convert_iso_date_to_friendly(this.view.dateRecurringStartPickerTarget.value) + " - " + this.convert_iso_date_to_friendly(this.view.dateRecurringEndPickerTarget.value);
        }
      }

      // Set the review date label
      const selectedEndOption = this.view.dateRecurringEndOptionsTarget.querySelector("sl-radio[value='" + this.view.dateRecurringEndOptionsTarget.value + "']");
      reviewDateLabel = selectedEndOption.getAttribute("label");
    }

    // Update date frequency in detail header
    this.view.reviewDateFrequencyTargets.forEach(target => {
      target.innerHTML = selectedFrequency;
    });

    // Review step: Custom date frequency for the review step
    this.view.reviewDateFrequencyCustomTargets.forEach(target => {
      target.innerHTML = reviewFrequencyDescription;
    });

    // Review step: Update date term
    this.view.reviewDateTermTargets.forEach(target => {
      // Update review date label (eg. Date, Start date, Start/End date)
      const reviewDateLabelNode = target.parentElement.querySelector(".label");
      if (reviewDateLabelNode) {
        reviewDateLabelNode.innerHTML = reviewDateLabel || reviewDateLabelNode.innerHTML;
      }

      // Update with content
      target.innerHTML = reviewDateDescription;
    });

    return true;
  }

  formatFirstAndFifteenthDate (date) {
    const dateString = date.toLocaleDateString();

    // Parse the date string
    const [month, day, year] = dateString.split("/");

    // Create a Date object (note: month is 0-indexed in JavaScript Date)
    const dateObj = new Date(year, month - 1, day);

    // Array of month names
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December",
    ];

    // Get the month name and day
    const monthName = monthNames[dateObj.getMonth()];
    const dayOfMonth = dateObj.getDate();

    // Format the date
    return `${monthName} ${dayOfMonth}, ${dateObj.getFullYear()}`;
  }

  generateFirstAndFifteenthEndDates () {
    const chosenStartDate = new Date(this.view.dateRecurringFirstAndFifteenthStartDayTarget.value.replaceAll("-", "/"));

    // Based on the start date, generate the end date options for the 1st and 15th of the month (max 6 months)
    this.view.dateRecurringFirstAndFifteenthEndDayTarget.innerHTML = "";

    // Advance the date to the next 1st or 15th of the month
    let dateToAdvance = chosenStartDate.getDate() === 15 ? new Date(chosenStartDate.getFullYear(), chosenStartDate.getMonth() + 1, 1) : new Date(chosenStartDate.getFullYear(), chosenStartDate.getMonth(), 15);

    // iterate over and go for 1st and 15th of the month for 6 months
    for (let i = 1; i < 26; i++) {
      const option = document.createElement("sl-option");
      option.role = "option";
      option.ariaSelected = "false";
      option.ariaDisabled = "false";
      option.value = dateToAdvance.toISOString().split("T")[0];
      option.innerHTML = this.formatFirstAndFifteenthDate(dateToAdvance);
      this.view.dateRecurringFirstAndFifteenthEndDayTarget.appendChild(option);
      dateToAdvance = dateToAdvance.getDate() === 1 ? new Date(dateToAdvance.getFullYear(), dateToAdvance.getMonth(), 15) : new Date(dateToAdvance.getFullYear(), dateToAdvance.getMonth() + 1, 1);
    }
  }
}
